body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn.getstarted.small {
  min-width: 50px;
}
.nounder{
  text-decoration: none;
}
.input-group-text .dropdown-item:focus{
  background-color: var(--themeclr);
}
#loader_div {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000000c7;
  z-index: 99999;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.loader img{
  animation: loader;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes loader{
  0%{
    transform: rotateY(360deg);
  }
  100%{
    transform: rotateY(0deg);
  }
}

.transform-component-module_wrapper__1_Fgj{
  height: unset !important;
  width: unset !important;
  overflow: auto;
}
.colors {
  padding: 15px 10px;
  background: #6c666691;
  /* max-width: 450px; */
  max-width: 510px;
  position: absolute;
  /* top: 14px; */
  top: 100px;
  left: 50px;
  z-index: 9;
  border-radius: 8px;
}
.colors p {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.colors .d-flex {
  gap: 7px;
}
.colors p span {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 2px;
  margin-left: 14px;
  margin-right: 2px;
}
.colors p span.gray{
  /* background-color: #f6df2d; */
  background-color: #808080;
}
.colors p span.avail{
  /* background-color: #f6df2d; */
  background-color: #66cae1;
}
.colors p span.wait{
  background-color: #00b6df;
}
.colors p span.soon{
  background-color: #ff59d8;
}
.noshrink {
  flex-shrink: 0;
}

@media(max-width:575px){
  .colors .d-flex {
    gap: 2px;
}
.colors {
  max-width: 372px;
    padding: 12px;
    left: 12px;
   
    
}
.colors p{
  font-size: 11px;
}
}

@media(max-width:575px){
  .colors {
      left: 5px;   
      right: 5px;
  }
}
.latestarticle .token_items{
  min-height: 132px;
}
.latestarticle .token_items h2 {
  color: #fff;
  /* font-family: extrabold; */
  /* padding: 8px; */
  margin-bottom: 0;
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 388px;
  white-space: nowrap;
}
.latestarticle .owl-nav{
  position: unset !important;
  width: 100%;
  top: 0;
  right: 0 !important;
  bottom: 0 !important;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.latestarticle .owl-nav .owl-prev{
  position: absolute;
  left: -6%;
  z-index: 2;
  top:40%;
  background: #55464600 !important;
  color: #fff !important;
}
.latestarticle .owl-nav .owl-next{
  position: absolute;
  right: -6%;
  z-index: 2;
  top:40%;
  background: #55464600 !important;
  color: #fff !important;

}
.carouselcontainer {
  max-width: 90%;
}
.latestarticle .owl-nav .owl-prev span,
.latestarticle .owl-nav .owl-next span {
  font-size: 52px;
}
.latestarticle  a.col_token {
  height: 100%;
  display: block;
}
.latestarticle .token_list{
  height: 100%;
}
.latestarticle .owl-item{
z-index: 1;
}
.latestarticle .owl-stage{
  /* display: flex; */
}
.carouselcontainer .token_list{
  margin-right: 0 !important;
}
.mapimg{
  background-image: url("./Assets/images/world.svg");
  width: 100vw;
  height: 100vh;
  background-repeat: repeat;
}
@media(max-width:767px){
  .latestarticle .owl-nav .owl-prev ,
  .latestarticle .owl-nav .owl-next{
    position: unset; 
  }
  .latestarticle .owl-nav{
    justify-content: center;
  }
}