@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn.getstarted.small {
  min-width: 50px;
}
.nounder{
  text-decoration: none;
}
.input-group-text .dropdown-item:focus{
  background-color: var(--themeclr);
}
#loader_div {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000000c7;
  z-index: 99999;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.loader img{
  -webkit-animation: loader;
          animation: loader;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

@-webkit-keyframes loader{
  0%{
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  100%{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
}

@keyframes loader{
  0%{
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  100%{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
}

.transform-component-module_wrapper__1_Fgj{
  height: unset !important;
  width: unset !important;
  overflow: auto;
}
.colors {
  padding: 15px 10px;
  background: #6c666691;
  /* max-width: 450px; */
  max-width: 510px;
  position: absolute;
  /* top: 14px; */
  top: 100px;
  left: 50px;
  z-index: 9;
  border-radius: 8px;
}
.colors p {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.colors .d-flex {
  grid-gap: 7px;
  gap: 7px;
}
.colors p span {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 2px;
  margin-left: 14px;
  margin-right: 2px;
}
.colors p span.gray{
  /* background-color: #f6df2d; */
  background-color: #808080;
}
.colors p span.avail{
  /* background-color: #f6df2d; */
  background-color: #66cae1;
}
.colors p span.wait{
  background-color: #00b6df;
}
.colors p span.soon{
  background-color: #ff59d8;
}
.noshrink {
  flex-shrink: 0;
}

@media(max-width:575px){
  .colors .d-flex {
    grid-gap: 2px;
    gap: 2px;
}
.colors {
  max-width: 372px;
    padding: 12px;
    left: 12px;
   
    
}
.colors p{
  font-size: 11px;
}
}

@media(max-width:575px){
  .colors {
      left: 5px;   
      right: 5px;
  }
}
.latestarticle .token_items{
  min-height: 132px;
}
.latestarticle .token_items h2 {
  color: #fff;
  /* font-family: extrabold; */
  /* padding: 8px; */
  margin-bottom: 0;
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 388px;
  white-space: nowrap;
}
.latestarticle .owl-nav{
  position: unset !important;
  width: 100%;
  top: 0;
  right: 0 !important;
  bottom: 0 !important;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.latestarticle .owl-nav .owl-prev{
  position: absolute;
  left: -6%;
  z-index: 2;
  top:40%;
  background: #55464600 !important;
  color: #fff !important;
}
.latestarticle .owl-nav .owl-next{
  position: absolute;
  right: -6%;
  z-index: 2;
  top:40%;
  background: #55464600 !important;
  color: #fff !important;

}
.carouselcontainer {
  max-width: 90%;
}
.latestarticle .owl-nav .owl-prev span,
.latestarticle .owl-nav .owl-next span {
  font-size: 52px;
}
.latestarticle  a.col_token {
  height: 100%;
  display: block;
}
.latestarticle .token_list{
  height: 100%;
}
.latestarticle .owl-item{
z-index: 1;
}
.latestarticle .owl-stage{
  /* display: flex; */
}
.carouselcontainer .token_list{
  margin-right: 0 !important;
}
.mapimg{
  background-image: url(/static/media/world.97e9b671.svg);
  width: 100vw;
  height: 100vh;
  background-repeat: repeat;
}
@media(max-width:767px){
  .latestarticle .owl-nav .owl-prev ,
  .latestarticle .owl-nav .owl-next{
    position: unset; 
  }
  .latestarticle .owl-nav{
    justify-content: center;
  }
}
:root {
  --themeclr: #F6DF2D;
  --textclr: #DEC508;

  /* --navhover: #C766FC; */
  /* --greens: #74E842; */
  --backcolor: #0c0c0c;
  --whites: #fff;

  --navhover:#FF67FF;
  --greens:#00D1FF;
}

a {
  text-decoration: none !important;
}

.no-bord {
  border: 0 !important;
}

.nav-tabs::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.nav-tabs::-webkit-scrollbar
{
	width: 9px;
  height: 7px;
	background-color: transparent;
}

.nav-tabs::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #17a2c1;
}
div:where(.swal2-container) div:where(.swal2-popup){
  background-color: #000 !important;
}
div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm){
  background-color: #17a2c1 !important;
}
.swal2-html-container, .swal2-title{
  color: #fff !important;
  line-height: 26px !important;
}
.swal2-title{

}

body {
  /* background-color: #000 !important; */
  background-color: #0c0c0c;
  background-color: var(--backcolor);
  /* background-image: url(./Assets/images/bg.png); */
  color: #fff;
  /* font-family: medium; */
  /* font-family: 'Kanit', sans-serif; */
  font-family: 'Space Mono', monospace;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: black;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  /* background-color: #74E842; */
  background-image: -webkit-linear-gradient(#c16bf0, #c16bf0);
  background-image: linear-gradient(#c16bf0, #c16bf0);
}

/* @font-face {
  font-family: themefont;
  src: url(./Assets/Font/Gilroy-Black.ttf);
}
@font-face {
  font-family: thin;
  src: url(./Assets/Font/Gilroy-Thin.ttf);
}
@font-face {
  font-family: bold;
  src: url(./Assets/Font/Gilroy-Bold.ttf);
}
@font-face {
  font-family: extrabold;
  src: url(./Assets/Font/Gilroy-ExtraBold.ttf);
}
@font-face {
  font-family: medium;
  src: url(./Assets/Font/Gilroy-Medium.ttf);
}
@font-face {
  font-family: normal;
  src: url(./Assets/Font/Gilroy-Regular.ttf);
}
@font-face {
  font-family: light;
  src: url(./Assets/Font/Gilroy-Light.ttf);
} */
.container.container-theme {
  max-width: 95%;
}

.normal {
  /* font-family: normal; */
}

.bold {
  /* font-family: bold; */
}

.innerheading {
  /* font-family: extrabold; */
}

.themeclr {
  color: #00D1FF !important;
  color: var(--greens) !important;
}

.textclr {
  color: #00D1FF;
  color: var(--greens)
}

.flex-1>* {
  flex: 1 1;
}

.jc-between {
  justify-content: space-between;
}

.br-14 {
  border-radius: 14px;
}

.bgtheme {
  /* background-color: var(--themeclr); */
}

button.ovalbtn {
  /* background: var(--themeclr);
  border-radius: 30px;
  padding: 4px 10px;
  font-weight: 700;
  border: 1px solid var(--themeclr); */
  background: #17a2c1;
  border-radius: 30px;
  padding: 4px 10px;
  font-weight: 500;
  border: 1px solid #17a2c1;
  color: #fff;
}

.btn.getstarted {
  background: #F6DF2D;
  background: var(--themeclr);
  font-family: 'extrabold';
  border-radius: 0;
  position: relative;
  min-width: 120px;
  padding: 10px 20px;
}

.launchpad .launchbox .btn.getstarted,
.lockedstake .launchbox .btn.getstarted {
  background-color: #4008DE;
  color: #fff;
}

.launchpad .innerheading,
.lockedstake .innerheading {
  color: #fff;
  color: var(--whites);
}

.smallp {
  max-width: 50%;
  margin: auto;
  color: #cbcbcb;
}
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}
button.chocobtn {
  /* background: var(--themeclr);
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 20%;
  
  font-weight: 700;
  border: 1px solid var(--themeclr); */
  background: #1793af;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 20%;
  font-weight: 500;
  border: 1px solid #1793af;
  color: #fff !important;
}

button.btn.getstarted.dark {
  background: #000;
  color: #fff;
}

button.btn.getstarted:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  left: -5px;
  bottom: 4px;
}

section {
  /* margin-top: 65px; */
  /* margin-top: 100px; */
  padding-top: 100px;
  /* margin-bottom: 75px; */
  padding-bottom: 75px;
}

button.btn.getstarted:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  left: -5px;
  bottom: 4px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.ban-cont p,
.features-list p {
  font-family: normal;
  color: #CBCBCB;
  line-height: 2;
}

.btn.btn-theme {
  background-color: #DEC508;
  background-color: var(--textclr);
}

/* header */
.land .navbar-nav .getstarted {
  display: none;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #80808061;
  z-index: 777;
}

.header-content {
  /* justify-content: center; */
  justify-content: space-evenly;
}

.userbtn {
  background: url(/static/media/walletbtn.a86cfe45.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #fff;
  min-width: 160px;
  min-height: 50px;
  /* text-transform: uppercase; */
  font-size: 14px;
  font-weight: 500;
  padding: 0px 16px;
  /* animation: glow 1s infinite alternate; */
}
.userbtn.small{
  min-width: 90px;
  min-height: 50px;
  font-size: 24px;
}
.userbtn:hover {
  color: #c766fc !important;
}

.headingbtn {
  /* color: #64C839; */
  /* color: var(--greens); */
  color: black;
  font-weight: 600;
 -webkit-transition: 0.5s;
 transition: 0.5s;
}

.headingbtn:hover{
  color: #00D1FF !important;
  color: var(--greens) !important;
  -webkit-filter: drop-shadow(2px 4px 6px #00d1ff33);
          filter: drop-shadow(2px 4px 6px #00d1ff33);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.header .bg-dark {
  background: #0c0c0c !important;
  background: var(--backcolor) !important;
}

.header .navbar-nav a:hover,
.header .navbar-nav a.nav-link.active {
  border-bottom: 2px solid #00D1FF;
  border-bottom: 2px solid var(--greens);
}

.navbar-dark .navbar-nav .nav-link {
  font-weight: 300;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #FF67FF !important;
  color: var(--navhover) !important;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #FF67FF !important;
  color: var(--navhover) !important;
}

.header .navbar-nav a {
  border-bottom: 3px solid transparent;
  margin-left: 20px;
  margin-right: 20px;
}

.header .navbar-nav a:last-child {
  margin-right: 0;
}

.header .navbar-nav a:first-child {
  margin-left: 0;
}

.lockedstake nav.navbar .btn.getstarted,
.launchpad nav.navbar .btn.getstarted {
  max-width: 150px;
}

/* tabs */
ul.nav-tabs {
  max-width: 435px;
  flex-flow: nowrap;
  overflow-x: auto;
  padding-bottom: 15px;
}

ul.nav-tabs li {
  flex: 1 1;
  white-space: nowrap;
}

li.nav-item button {
  background: transparent !important;
  border-color: transparent !important;
  color: #fff;
  width: 100%;
}

li.nav-item button:hover {
  /* color: #fff !important; */
  color: #FF67FF !important;
  color: var(--navhover) !important;
  border-bottom: 2px solid #00D1FF !important;
  border-bottom: 2px solid var(--greens) !important;
}

li.nav-item button.active {
  /* color: #000 !important; */
  /* background-color: #f6df2d !important; */
  /* border-radius: 5px; */
  color: #FF67FF !important;
  color: var(--navhover) !important;
  border-bottom: 2px solid #00D1FF !important;
  border-bottom: 2px solid var(--greens) !important;
  /* background-image: linear-gradient(#74E842,#c16bf0) !important; */
  /* background: url("./Assets/images/btn-bg.png") !important;
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  background-position: center;
  min-width: 130px;
  min-height: 46px; */
}

ul.nav-tabs {
  border: 0 !important;
}


.news {
  position: relative;
}

/* .newshead{margin-top:-24px;} */
.news .items {
  position: relative;
  height: 90vh;
}

.news .itemsfles {
  display: flex;
  align-items: start;
  flex-direction: column;
  max-height: 100vh;
  justify-content: center;
  width: 50%;
  height: 100%;
  min-height: 80vh;
  position: absolute;
  bottom: 0;
  left: 41px;
  right: 0;
  top: 0;
  background: #00000080;
  padding: 8px;
}

.news .itemsfles h2 {
  font-size: 50px;
  font-weight: 600;
}

.news .itemsfles p {
  font-size: 16px;
  font-weight: 400;
}

.news .owl-carousel .owl-nav {
  position: absolute;
  bottom: 50px;
  right: 80px;
}

.news .owl-carousel .owl-nav button.owl-next {
  background-color: #ffffff80;
  color: #5e5e5e;
  font-size: 28px;

  border-radius: 50px;
  width: 42px;
}

.news .owl-carousel .owl-nav button.owl-prev {
  /* background-color: #ffffff80;
      color: #5e5e5e; */
  background-color: #00D1FF;
  background-color: var(--greens);
  color: #ffff;
  font-size: 28px;
  border-radius: 50px;
  width: 42px;
}

.news .owl-carousel .owl-nav button.owl-prev:hover,
.news .owl-carousel .owl-nav button.owl-next:hover {
  /* background-color: #fff; */
  background-color: #00D1FF;
  background-color: var(--greens);
    color: #fff;
}

.Editor_pick {
  cursor: pointer;
}

.Editor_pick h2 {
  color: #fff;
  /* font-family: extrabold; */
  padding: 20px 0;
  font-weight: 600;
}

.col_token:nth-child(even) .token_list {
  /* background-color: #DEC508;
  border: 1px solid #DEC508; */
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 8px;
  margin-top: 20px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-bottom: 6px;
}

.token_list {
  /* background-color: #fff; */
  position: relative;
  overflow: hidden;
  background-color:transparent;
  border: 1px solid #fff;
  border-radius: 8px;
  margin-top: 20px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-bottom: 6px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  /* background: url("./Assets/images/tablebg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
}
.token_list:hover{
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.token_list span {
  position: absolute;
  border-radius: 100vmax;
}
.tokenimg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;
}

.tokenimg img {
  /* min-height: 250px;
  object-fit: cover;
  max-width: 100%; */
  min-height: 260px;
  object-fit: contain;
  max-width: 94%;
  /* margin-top: 20px; */
  margin: auto;
  margin-top: 4px;
  transition: transform .5s ease,-webkit-transform .5s ease;
}
.tokenimg img:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.token_items{
  /* padding: 4px 14px; */
  padding: 4px 14px 20px 14px;
}
.token_items h2 {
  /* color: #000; */
  color: #fff;
  /* font-family: extrabold; */
  /* padding: 8px; */
  margin-bottom: 0;
  font-size: 24px;
}

.token_items p {
  /* color: #000; */
  color: #fff;
  /* padding: 8px; */
  font-size: 14px;
  margin-bottom: 0;
}

.launchpad .token_list {
  background-color: #DEC508;
  border: 1px solid #DEC508;
  border-radius: 5px;
  margin-top: 20px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-bottom: 6px;
  margin-right: 8px;
}

.launchpad .col_token .token_list {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-top: 20px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-bottom: 6px;
  margin-right: 8px;
}

.launchpad .tokenimg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;
  padding: 0;
}

.launchpad .tokenimg img {
  min-height: 250px;
  object-fit: cover;
  max-width: 100%;
}

.newdetail {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  cursor: pointer;
}

.newdetail img {
  min-height: 100vh;
  max-width: 100%;
  object-fit: cover;
  min-width: 100%;
  /* min-height: 100vh;
    max-width: 30%;
    object-fit: contain;
    min-width: 30%;y
    margin: auto; */
}
.details img{
  min-height: 100vh !important;
    max-width: 100% !important;
    object-fit: cover !important;
    min-width: 100% !important;
    margin: auto !important;
}
.newdetailsec {
  /* background-color: #fff;
  margin-top: -100px; */
  background-color: transparent;
  margin-top: -100px;
  border: 1px solid #fff;
  border-radius: 8px;

}
.cowa{
  color: #fff !important;
}
.modal-header {
  border-bottom: 1px solid #dee2e659 !important;
}
.connect p{
  cursor: pointer;
}
.connect p:hover{
  color:#00D1FF;
  color:var(--greens);
}
.recent_post {
  /* background-color: #fff;
  margin-top: 50px;
  padding: 20px; */
  background-color: transparent;
    margin-top: 50px;
    padding: 20px;
    border: 1px solid #fff;
    border-radius: 6px;
}

.vdieo_hide {
  border: 1px solid #eee;
  padding: 0px 40px 40px 40px;
  text-align: center;
}

.form_loist {
  background-color: #fff;
  margin-top: 50px;
  padding: 20px;
}

.newdetailsec h2 {
  color: #fff;
  font-size: 40px;
  padding: 30px 30px 10px 30px;
}

.recent_post h2 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

.form_loist h2 {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 10px;
}

.imgdive {
  display: flex;
  padding: 0 5px 40px 5px;
  font-size: 0;
  flex-flow: row wrap;
  justify-content: center;
  grid-gap: 1px;
  gap: 1px;
  max-width: 800px;
  margin: 0 auto;
}

.imgs {
  margin: 0;
  min-width: 201px;
  flex: 0.67 1;
  -webkit-transition: .5s;
  transition: .5s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 250px;
  max-height: 250px;
}

.imgs img {
  max-width: 100%;
  min-height: 250px;
  object-fit: cover;
}

.recentblogdet {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;
}

.recent_post a {
  color: #818181 !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  word-break: break-word;
}
.newsbackimg{
  max-width: 100% !important;
}
.recent_post a:hover {
  color: #00D1FF !important;
  color: var(--greens) !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
}

.recent_post img {
  width: 80px;
  height: 70px;
  object-fit: contain;
}

.dayscmment {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 30px;
  cursor: pointer;
}

.dayscmment p {
  color: #818181;
  font-size: 14px;
  margin-right: 10px;
}

.newdetailsec .textconete {
  color: #818181;
  font-size: 14px;
  margin-right: 10px;
  padding: 0px 30px 30px 30px;
}
.newdetailsec .textconete span{
  color: #B8B8B8 !important;
}
.newdetailsec .textconete strong{
  color: #fff !important;
}
.dayscmment i {
  color: #818181;
  font-size: 14px;
}

/* banner */

.heading {
  font-size: 55px;
}

.banner {
  position: relative;
  overflow: hidden;

}

.bannerimg img {
  width: 100%;
  max-width: 500px;
}

/* feature */
.features-list {
  border: 1px solid transparent;
  border-radius: 14px;
}

.features-list:hover {
  border: 1px solid #F6DF2D;
  border: 1px solid var(--themeclr);
}

/* roadmap */
h6.roadmapbtn:after {
  position: absolute;
  content: "";
  height: 46px;
  width: 1px;
  background: white;
  top: -31px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

h6.roadmapbtn {
  position: relative;
}

.line:after {
  content: "";
  height: 20px;
  width: 20px;
  background: #000000;
  display: block;
  color: #F6DF2D;
  color: var(--themeclr);
  position: absolute;
  border-radius: 50%;
  border: 2px solid white;
  left: 0;
  right: 0;
  margin: auto;
  top: 26%;
  z-index: 1;
}

.car-item:after {
  width: 100%;
  content: "";
  height: 6px;
  background: #F6DF2D;
  background: var(--themeclr);
  position: absolute;
  left: 0;
  right: 0;
  /* top: 26%; */
  top: 29%;
}

.fullinerelative {
  position: relative;
}

.fullline {
  position: absolute;
  height: 6px;
  width: 100%;
  background: #2D2D2D;
  top: 77px;
  border-radius: 10px;
}

.roadmap .owl-item:first-child .car-item:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.logogrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px;
  gap: 50px;
  place-items: center;
}

.logogrid img {
  width: 10vw;
  max-height: 3vw;
}

.notyetstart .line:after {
  background: #F6DF2D;
  background: var(--themeclr);
  border-color: #817E7E;
  border-width: 3px;
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
  background: transparent;
}

.notyetstart:after {
  background: transparent;
}

.inprogress:after {
  width: 50%;
}

.car-item h6:first-child {
  margin-bottom: 66px;
}

.car-item h6:first-child {
  margin-bottom: 66px;
}

.car-item {
  text-align: center;
  position: relative;
  width: 100%;
}

.car-item p {
  color: #CBCBCB;
  font-size: 15px;
  padding-right: 15px;
  min-height: 88px;
}

.infos p,
.marketcapital p {
  font-size: 18px;
}

.roadmap .owl-nav button span {
  margin-top: 0 !important;
}

.car-item .line+h6 {
  margin-top: 80px;
}

.car-item h6 {
  color: white;
}

h6.roadmapbtn {
  background: #F6DF2D;
  background: var(--themeclr);
  max-width: 114px;
  padding: 7px;
  border-radius: 20px;
  color: #000;
  margin: auto;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 11px;
}

.slider_sec_1 img {
  max-width: 170px;
  margin: 20px auto;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer;
  /* max-height: 110px;
  min-height: 110px; */
}

.slider_sec_1 .owl-carousel .owl-nav .owl-prev span,
.slider_sec_1 .owl-carousel .owl-nav .owl-next span {
  /* display: none; */
  color: #97afd5;
}

.slider_sec_1 .owl-carousel .owl-nav .owl-prev span,
.slider_sec_1 .owl-carousel .owl-nav .owl-next span {
  color: #97afd5;
  border: 1px solid #97afd5;
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  font-size: 35px;
  font-weight: 100;
  line-height: 0.8;
  margin-top: 40px;
}

.slider_sec_1 .owl-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 150px !important;
}

.slider_sec_1 .owl-item div,
.slider_sec_1 .owl-item div a {
  height: 100%;

}

.slider_sec_1 .owl-item .car-item>div {
  padding-top: 40px;
}



/* launchpad */
.bannerbox .innerheading {
  font-size: 45px;
  text-shadow: 0px 8px 2px #5f59592b;
  font-weight: 600;
}

.bordbox {
  /* border: 1px solid #c766fc87; */
  background: url(/static/media/tablefit.ed7cdb38.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 100px 40px !important;
}
.bordbox1 {
  /* border: 1px solid #c766fc87; */
  background: url(/static/media/tablebg1.e8bafadd.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 40px !important;
}
.launchbox {
  background-color: #0F0F0F;
  border: 1px solid #313131;
  position: relative !important;
  z-index: 7;
  /* overflow: hidden; */
}

.launchpad button.chocobtn {
  position: absolute;
  right: 24px;
  top: -17px;
}

.progress {
  background-color: #272727;
  height: 10px;
}

.progress-bar {
  /* background: var(--textclr); */
  background: #8243a5;
  border-radius: 5px;
}

.launchbox h6 {
  /* font-family: 'bold'; */
  font-size: 15px;
  font-weight: 500 !important;
}

.logocont img {
  /* border: 2px solid var(--themeclr);
  border-radius: 50%; */
  height: 40px;
  width: 100px;
  padding: 5px;
  object-fit: contain;
  margin-top: -6px;
}

.logocont span {
  text-transform: uppercase;
  /* font-family: normal; */
}

.launchpad .bgtheme .innerheading {
  text-shadow: 0px 8px 2px #5f59592b;
  font-weight: 600;
}

.calcicon {
  width: 12px;
  -webkit-filter: hue-rotate(45deg);
          filter: hue-rotate(45deg);
}


/* modal */
.modal-backdrop.show {
  opacity: .9;
}

.modal-content {
  background: #0F0F0F;
}

.modal-backdrop {
  /* background-color: #2E2E28; */
  background-color: #000000;
}

.yellowbox {
  /* border: 1px solid var(--themeclr); */
  background: #000000;
  /* border-radius: 15px; */
  background: url(/static/media/tablefit.ed7cdb38.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 60px 40px !important;
}

.bannerbox {
  min-height: 181px;
}

.modal .yellowbox.connect.text-center {
  max-width: 185px;
  margin: auto;
}

.modal .yellowbox img {
  max-width: 50px;
  height: auto;
}

.modal-sm {
  max-width: 400px;
  margin: auto;
}

.btn-link.close:hover {
  color: #F6DF2D;
  color: var(--themeclr);
}

.input-group input {
  background: #000 !important;
  border: none;
  border-radius: 8px;
  color: #fff;
  min-height: 48px;

}

.input-group.h-75 input {
  height: 75px;
}

span.input-group-text {
  background: #000;
  border: none;
  border-radius: 8px;
}

.form-control:focus {
  color: #ffff !important;
}

.input-group {
  /* border: 1px solid var(--themeclr); */
  border: 1px solid #00d1ff61;
  border-radius: 8px;
}

.input-group input:focus {
  box-shadow: none;
}

.form_loist label {
  color: #5e5e5e;
  font-size: 12px;
}

.labelforme {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.form_loist .labelforme textarea {
  --webkit-appearance: none !important;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  border: 1px solid #5e5e5e33 !important;
  margin-top: 10px;
  width: 98%;
}

.form_loist .labelforme textarea:focus {
  outline: none;
}

.form_loist .labelforme input[type="text"] {
  --webkit-appearance: none !important;
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  border: 1px solid #5e5e5e33 !important;
  max-width: 95%;
  min-width: 80%;
  height: 40px;
  width: 100%;
}

.labelformecheck {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.submitfomer {
  background-color: #F6DF2D;
  font-size: 14px;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  padding: 12px 14px !important;
  height: 50px;
  margin-top: 30px;
  border: 1px solid #F6DF2D;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.vdieo_hide iframe {
  max-width: 100%;
  width: 550px;
  max-height: 300px;
  height: 300px;
  min-height: 300px;
}

.submitfomer:hover {
  background-color: transparent;
  color: #000;
}

.labelformecheck p {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
}

.form_loist .labelforme input:focus {
  outline: none;
}

.inputfoe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

/* footer */
.footer {
  border-top: 1px solid #80808078;
  padding-top: 25px;
  padding-bottom: 10px;
  margin-top: 54px;
  position: relative;
}

.footer .textclr {
  /* font-family: light; */
  color: #fff;
  font-size: 12px;
}

.footer a svg {
  /* color: #74E842; */
  color: #00D1FF;
  color: var(--greens);
  font-size: 18px;
  margin-left: 10px;
}

.footer a svg:hover {
  /* color: #ab3ee7; */
  color: #FF67FF;
  color: var(--navhover);
}

.footer ul li {
  list-style-type: none;
  display: inline-block;
}

.footer ul li {
  margin-left: 10px;
  margin-right: 10px;
}

.footer ul li:first-child {
  margin-left: 0;
}

.footer ul li span {
  color: #DEC508;
  color: var(--textclr);
  font-size: 25px;
}



@media(min-width:1601px) {

  .car-item p {
    min-height: 78px;
  }

  .line:after {
    top: 23%;
  }

}

@media(max-width:1199px) {
  .smallp {
    max-width: 100%;
  }

  .news .itemsfles {
    min-height: 60vh;
  }

  .news .itemsfles h2 {
    font-size: 40px !important;
  }

  .news .items {
    height: 60vh;
  }

  .news .owl-carousel .owl-nav {
    bottom: 50px;
  }
}

@media(max-width:992px) {
  .news .itemsfles h2 {
    font-size: 40px;
  }

  .news .itemsfles {
    min-height: 50vh;
  }
}

@media(max-width:991px) {

  .navbar-collapse {
    position: absolute;
    background: black;
    width: 100%;
    top: 87px;
    left: 0px;
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .news .items {
    height: 90vh;
    background-size: 100%;
  }

  .news .itemsfles {

    width: 100%;
  }

  .news .itemsfles p {
    width: 80%;
  }

  .news .itemsfles h2 {
    font-size: 36px;
  }

  .navbar-collapse a {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  .bannerbox .innerheading {
    font-size: 30px;
  }

  .header .navbar-nav a {
    margin: 0;
  }

  .heading {
    font-size: 40px;
  }

  .bannerimg img {
    max-width: 390px;
  }
}

@media(max-width:767px) {

  .bordbox {
    /* border: 1px solid #c766fc87; */
    background:url(/static/media/tablebg1.e8bafadd.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 100px 40px !important;
  }

  .infos p,
  .marketcapital p {
    font-size: 16px;
  }

  .news .owl-carousel .owl-nav {
    bottom: 0px;
  }

  .news .itemsfles {
    min-height: 41vh;
  }

  .news .items {
    height: 90vh;
    background-size: 100% 100%;
  }

  .navbar-brand img {
    height: 40px;
  }

}

@media(max-width:575px) {
  .bordbox {
    padding: 70px 20px 30px 20px !important;
}
  .footer .textclr {
    text-align: center;
  }

  .footer .col-12 {
    text-align: center !important;
  }
}

.logos_sec {
  max-width: 80%;
  margin: 0 auto;
}

@media(max-width:575px) {
  .newdetailsec {
    margin-top: -10px;
  }

  .logogrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media(max-width:425px) {
  .slider_sec_1 img {
    max-width: 99px;
    margin: 0px auto;
  }

  footer {
    text-align: center !important;
    margin: 0 auto !important;
  }

  .newdetail {
    min-height: unset;
    max-height: unset;
  }

  .newdetail img {
    min-height: unset;
  }

  .items .newdetail img {
    min-height: 100vh;
  }

  .news .itemsfles p {
    font-size: 14px;
  }

  .newdetailsec h2 {
    font-size: 28px;
  }

  .connect p {
    font-size: 12px;
  }

  .news .itemsfles h2 {
    font-size: 24px !important;
  }

  .news .owl-carousel .owl-nav button.owl-next,
  .news .owl-carousel .owl-nav button.owl-prev {
    font-size: 22px;
    width: 32px;
  }

  .news .owl-carousel .owl-nav {
    left: 0;
    right: 0;
  }
}

@media(min-width:1800px) {
  .heading {
    font-size: 75px;
  }
}

.news .Editor_pick.firstsec .token_list {
  height: 100%;
}

.newdetailsec h2 {
  font-weight: 900;
  background: #0000008f;
}

.newdetailsec .textconete b {
  font-size: 16px;
}

.launchpad .noticelink {
  /* color: #838383 !important; */
  color: #ffff !important;
  font-size: 18px;

}

.launchpad .menu_list {
  position: relative;

}

.launchpad .notice_link {
  position: absolute;
  right: 0px;
}



/* .Editor_pick.firstsec .col_token{
  height: 100%;

} */
:root {
    --themeclr: #F6DF2D;
    --textclr: #DEC508;

    /* --navhover: #C766FC; */
    /* --greens: #74E842; */
    --navhover:#FF67FF;
    --greens:#00D1FF;
    --spancolor: #64c839;
    --backcolor: #0c0c0c;
    --whites: #fff;
}

/* canvas {display: block;} */
.titles span {
    color: #fff;
    color: var(--whites);
}

.titles .typed-cursor {
    color: #74E842;
}

.allsection {
    padding-top: 100px;
}

.title {
    font-weight: 400 !important;
}

.title-sub {
    font-size: 52px;
    /* animation: glitch 3s linear infinite; */
}

.titlespan {
    color: #64c839;
    color: var(--spancolor);
}

.banner .owl-nav {
    display: none;
}

.banner .owl-theme .owl-dots {
    text-align: start;
    margin-top: 4%;
}

.banner .owl-theme .owl-dots .owl-dot span {
    width: 6px;
    height: 6px;
}

.banner .owl-theme .owl-dots .owl-dot:hover span {
    background: #c766fc;
}

.banner .owl-theme .owl-dots .owl-dot.active span {
    background: #c766fc;
}

.backimg {
    /* background: url("./Assets/images/tablebg.png"); */
    max-width: 380px;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 168px;
    background-size: 100% 100%;
    padding: 36px 26px;
}

.backimg1 {
    /* background: url("./Assets/images/tablebg.png"); */
    /* max-width: 550px;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 168px;
    background-size: 100% 100%;
    padding: 36px 50px; */
    background-repeat: no-repeat;
    width: 100%;
    min-height: 260px;
    /* background-size: 530px 250px; */
    background-size: 100% 100%;
    position: relative;
    z-index: -1;
    padding: 50px 60px 10px 2px;
}

.defrobo {
    position: absolute;
    left: -45px;
    top: 30px;
    max-width: 245px;
}

.defrobo1 {
    position: absolute;
    left: 0%;
    top: -9%;
    max-width: 9%;
    -webkit-transform: rotate(174deg);
            transform: rotate(174deg);
    z-index: -1;
}

.lefttext {
    padding-left: 210px;
    padding-bottom: 42px;
}

.cardbreakimg {
    /* background: url("./Assets/images/grap\ \(1\).svg"); */
    background-repeat: no-repeat;
    width: 100%;
    min-height: 250px;
    background-size: 100% 100%;
    padding: 36px 50px;
    position: relative;
    z-index: -1;
    text-align: center;
}

.roboo {
    max-width: 85% !important;
}

.useappbtn {
    position: absolute;
    /* right: 12%; */
    right: 6%;
    bottom: -5%;
}

.useappbtn::after {
    content: "";
    position: absolute;
    border: 12px solid #0c0c0c;
    width: 120px;
    left: -6px;
    bottom: 12px;
    z-index: -1;
}

.texts {
    font-size: 13px;
    max-width: 400px;
    line-height: 22px;
    color: #B8B8B8;
}

.sub-text {
    line-height: 22px;
    font-size: 13px;
    color: #d5d5d58f;
}

.lasttext {
    color: #B8B8B8;
}

.aitext {
    color: #B8B8B8;
    font-size: 13px;
    line-height: 22px;
}

.greengradient {
    background: #64c839db 0% 0% no-repeat padding-box;
    opacity: 0.52;
    -webkit-filter: blur(50px);
            filter: blur(50px);
    width: 75px;
    height: 75px;
    position: absolute;
    bottom: 50px;
    left: 70px;
    z-index: -1;
}

.greengradient1 {
    background: #64c83947 0% 0% no-repeat padding-box;
    opacity: 0.52;
    -webkit-filter: blur(70px);
            filter: blur(70px);
    width: 200px;
    height: 350px;
    position: absolute;
    bottom: 50px;
    left: 5%;
    top: 27%;
}

.greengradientround {
    background: #64c839c7 0% 0% no-repeat padding-box;
    opacity: 0.52;
    -webkit-filter: blur(50px);
            filter: blur(50px);
    width: 90px;
    height: 80px;
    position: absolute;
    top: 12%;
    right: 4%;
    z-index: -1;
}

.violentgradient {
    /* background: #c766fc40 0% 0% no-repeat padding-box;  */
    background: #00c4f012 0% 0% no-repeat padding-box;
    opacity: 0.52;
    -webkit-filter: blur(70px);
            filter: blur(70px);
    width: 200px;
    height: 300px;
    position: absolute;
    bottom: 50px;
    right: 5%;
    top: 35%;
    z-index: -1;
}

.bannerlast {
    padding-bottom: 10px !important;
    z-index: 7;
    position: relative;
}
.dropdown-menu {
    border: 1px solid #80808070 !important;
    background-color: #0f0f0f !important;
}
.dropdown-item {
    color: #ffff !important;
}
.dropdown-item:hover {
    background-color: #1793af !important;
}
.greengradientlefts {
    background: #64c83936 0% 0% no-repeat padding-box;
    opacity: 0.52;
    -webkit-filter: blur(50px);
            filter: blur(50px);
    width: 200px;
    height: 300px;
    position: absolute;
    top: 4%;
    left: 4%;
    z-index: -1;
}

.greengradient2 {
    background: #64c83938 0% 0% no-repeat padding-box;
    opacity: 0.52;
    -webkit-filter: blur(80px);
            filter: blur(80px);
    width: 200px;
    height: 300px;
    position: absolute;
    bottom: 50px;
    right: 10%;
    top: 45%;
}

.greengradientcenter {
    background: #64c8395e 0% 0% no-repeat padding-box;
    opacity: 0.52;
    -webkit-filter: blur(50px);
            filter: blur(50px);
    width: 220px;
    height: 250px;
    position: absolute;
    top: 6%;
    left: 42%;
    z-index: -1;
}

.greengradientleft {
    /* background: #64c83914 0% 0% no-repeat padding-box; */
    background: #00d1ff05 0% 0% no-repeat padding-box;
    opacity: 0.52;
    -webkit-filter: blur(50px);
            filter: blur(50px);
    width: 300px;
    height: 300px;
    position: absolute;
    top: 6%;
    left: 4%;
    z-index: -1;
}

.greengradientright {
    /* background: #64c83914 0% 0% no-repeat padding-box; */
    background: #00d1ff05 0% 0% no-repeat padding-box;
    opacity: 0.52;
    -webkit-filter: blur(50px);
            filter: blur(50px);
    width: 300px;
    height: 300px;
    position: absolute;
    top: 6%;
    right: 4%;
    z-index: -1;
}

.lastline {
    position: absolute;
    max-width: 60%;
    left: 23%;
    top: 35%;
    z-index: -1;
}

.leftline {
    position: absolute;
    right: 2%;
    top: 30%;
    -webkit-transform: rotate(310deg);
            transform: rotate(310deg);
    max-width: 50%;
    z-index: -1;
}

.greengradientfooter {
    background: #00d1ff21 0% 0% no-repeat padding-box;
    opacity: 0.52;
    -webkit-filter: blur(50px);
            filter: blur(50px);
    width: 120px;
    height: 60px;
    position: absolute;
    top: 6%;
    left: 42%;
    z-index: -1;
}

.firstsection {
    padding-top: 150px !important;
    /* overflow: unset !important; */
}
/* .firstsection::before{
    content: "";
    background: url("./Assets/images/cross-line-1.png") !important;
    background-repeat: no-repeat;
    position: absolute;
    top: 8%;
    max-width: 60%;
    z-index: -1;
} */
.crossline {
    position: absolute;
    top: 8%;
    max-width: 60%;
    z-index: -1;
    /* animation: 6s ease-in-out infinite float; */
}

.crossline1 {
    position: absolute;
    max-width: 46%;
    left: 1%;
    top: 5%;
    z-index: -1;
}

.ellipse {
    position: absolute;
    top: 22%;
    max-width: 35%;
    left: 5%;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation: 6s ease-in-out infinite floatx;
            animation: 6s ease-in-out infinite floatx;
}

.polygon {
    position: absolute;
    top: -20%;
    max-width: 30%;
    right: 5%;
    -webkit-animation: 6s ease-in-out infinite float;
            animation: 6s ease-in-out infinite float;
}

.polygon2 {
    position: absolute;
    max-width: 35%;
    right: 0%;
    bottom: 14%;
    z-index: -1;
}

.userapp {
    padding-top: 25px !important;
    position: relative;
    z-index: 7;
}

.leftside {
    max-width: 46%;
    position: absolute;
    right: 0;
    bottom: 0%;
    z-index: -1;
}

.circle {
    position: absolute;
    max-width: 34%;
    top: 0%;
    left: 30%;
    z-index: -1;
    -webkit-animation: spin 7s linear infinite;
    animation: spin 7s linear infinite;
}

.circlelanch {
    position: absolute;
    max-width: 36%;
    top: -4%;
    left: 32%;
    z-index: -1;
    -webkit-animation: spin 7s linear infinite;
            animation: spin 7s linear infinite;
}

.circle1 {
    max-width: 30% !important;
    top: 0% !important;
    left: 35% !important;
}

.ployright {
    max-width: 35%;
    position: absolute;
    right: 0%;
    bottom: 0%;
    z-index: -1;
    -webkit-animation: spin 16s linear infinite;
    animation: spin 16s linear infinite;
}

.ploycont {
    max-width: 9% !important;
    right: 20% !important;
    bottom: 6% !important;
    -webkit-filter: brightness(0.4);
            filter: brightness(0.4);
}

.ploystackcont {
    max-width: 28% !important;
    right: 8% !important;
    bottom: 16% !important;
    -webkit-filter: brightness(0.3);
            filter: brightness(0.3);
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.yy {
    /* animation: glitch 3s linear infinite; */
}

@-webkit-keyframes glitch {

    2%,
    64% {
        -webkit-transform: translate(12px, 0) skew(0deg);
                transform: translate(12px, 0) skew(0deg);
    }

    4%,
    60% {
        -webkit-transform: translate(-4px, 0) skew(0deg);
                transform: translate(-4px, 0) skew(0deg);
    }

    62% {
        -webkit-transform: translate(0, 0) skew(5deg);
                transform: translate(0, 0) skew(5deg);
    }
}

@keyframes glitch {

    2%,
    64% {
        -webkit-transform: translate(12px, 0) skew(0deg);
                transform: translate(12px, 0) skew(0deg);
    }

    4%,
    60% {
        -webkit-transform: translate(-4px, 0) skew(0deg);
                transform: translate(-4px, 0) skew(0deg);
    }

    62% {
        -webkit-transform: translate(0, 0) skew(5deg);
                transform: translate(0, 0) skew(5deg);
    }
}

@-webkit-keyframes float {

    0%,
    100% {
        -webkit-transform: translatey(0);
                transform: translatey(0);
    }

    50% {
        -webkit-transform: translatey(-20px);
                transform: translatey(-20px);
    }
}

@keyframes float {

    0%,
    100% {
        -webkit-transform: translatey(0);
                transform: translatey(0);
    }

    50% {
        -webkit-transform: translatey(-20px);
                transform: translatey(-20px);
    }
}

@-webkit-keyframes floatx {

    0%,
    100% {
        -webkit-transform: translatex(0);
                transform: translatex(0);
    }

    50% {
        -webkit-transform: translatex(-30px);
                transform: translatex(-30px);
    }
}

@keyframes floatx {

    0%,
    100% {
        -webkit-transform: translatex(0);
                transform: translatex(0);
    }

    50% {
        -webkit-transform: translatex(-30px);
                transform: translatex(-30px);
    }
}

.roboo1 {
    max-width: 70% !important;
}

.roboo1:hover {
    -webkit-filter: drop-shadow(2px 4px 6px #5bb634);
            filter: drop-shadow(2px 4px 6px #5bb634);
}

.nobtn {
    /* background: url("./Assets/images/1-btn.png"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    color: #fff;
    min-width: 75px;
    min-height: 46px;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 16px;
}
.nobtn:hover {
    color: #FF67FF;
    color: var(--navhover);
}
/* .item span>:first-child{
    font-size: 32px;
} */
.titlelist span {
    font-size: 30px;
}

/* .typed-cursor {
    color: #64c839;
} */

.noappbtn {
    position: absolute;
    right: 19%;
    bottom: -5%;
}

.greenrectangle {
    position: absolute;
    right: 10%;
    max-width: 25%;
    bottom: 63%;
    -webkit-animation: 6s ease-in-out infinite float;
            animation: 6s ease-in-out infinite float;
}
@media only screen and (max-width:1200px) {
    .noappbtn {
        right: 15%;
    }
    .details img {
        max-width: 80% !important;
    }
}

@media only screen and (min-width:992px) and (max-width: 1000px) {
    .noappbtn {
        right: 10%;
    }
    .useappbtn {
        position: absolute;
        right: 6%;
        bottom: -6%;
    }

    .useappbtn::after {
        bottom: 15px;
    }

    .cardbreakimg {
        padding: 30px 20px;
        min-height: 190px;
    }

    .defrobo {
        left: -50px;
        top: 60px;
        max-width: 180px;
    }

    .lefttext {
        padding-left: 140px;
    }

    .circle {
        max-width: 50%;
        left: 25%;
    }

    .ployright {
        max-width: 50%;
    }

    .circle1 {
        max-width: 50% !important;
        top: 0% !important;
        left: 25% !important;
    }
}

@media only screen and (max-width: 991px) {
    .defrobo {
        left: 0px;
        top: 30px;
        max-width: 180px;
    }

    .circle1 {
        max-width: 60% !important;
        top: 0% !important;
        left: 20% !important;
    }

    .circle {
        max-width: 60%;
        top: 0%;
        left: 20%;
    }
}

@media only screen and (max-width: 800px) {
    .details img {
        max-width: 80% !important;
    }
    /* .newdetail img {
        object-fit: cover;
    } */
    .noappbtn {
        right: 0%;
    }
    .backimg1 {
        padding: 50px 10px 10px 2px;
    }

    .cardbreakimg {
        padding: 36px 20px;
        min-height: 190px;
    }

    .defrobo {
        top: 50px;
    }

    .defrobo1 {
        top: -4%;
    }

    .circlelanch {
        top: 10%;
    }

    .newdetail img {
        max-width: 100%;
    }

    /* .token_items h2 {
        font-size: 19px;
    } */
}

@media only screen and (min-width:576px) and (max-width: 767px) {

    .bordbox {
        padding: 70px 40px 30px 40px !important;
    }
    .noappbtn {
        right: 25%;
    }
    .titlelist span {
        font-size: 28px;
    }

    .token_items h2 {
        font-size: 19px;
    }

    .defrobo1 {
        top: -4%;
        max-width: 15%;
    }

    .lefttext {
        padding-left: 100px;
    }

    .useappbtn {
        right: 6%;
    }

    .greengradientcenter {
        width: 160px;
        height: 190px;
        top: 2%;
        left: 30%;
    }

    .circle1 {
        max-width: 60% !important;
        top: 0% !important;
        left: 20% !important;
    }

    .circle {
        max-width: 70%;
        top: 0%;
        left: 17%;
    }

    .ployright {
        max-width: 70%;
        right: 0%;
        bottom: 0%;
    }

    section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .violentgradient {
        top: 66%;
    }
}

@media only screen and (max-width: 575px) {
    .details img {
        min-height: unset !important;
        max-width: 85% !important;
    }
    .launchpad .notice_link {
        top: -1%;
    }

    .defrobo1 {
        top: -4%;
        max-width: 25%;
    }

    .lefttext {
        /* padding-left: 10px; */
        padding-left: 25px;
        padding-right: 20px;
    }
    .titlelist span {
        font-size: 23px;
    }
    .title-sub {
        font-size: 32px;
    }

    .greengradient1,
    .violentgradient,
    .greengradientright {
        display: none;
    }

    .backimg {
        /* padding: 20px 2px; */
        padding: 40px 20px;
    }

    .backimg1 {
        padding: 200px 2px 10px 2px;
    }

    /* .backimg1,.backimg{
        background: unset !important;
    } */
    .useappbtn {
        right: 0%;
    }

    .greengradientcenter {
        width: 150px;
        height: 150px;
        top: 4%;
        left: 25%;
    }

    section {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .defrobo {
        /* top: 5px;
        left: -15px; */
        top: 30px;
        left: 10px;
    }

    .circle1 {
        max-width: 95% !important;
        top: 0% !important;
        left: 0% !important;
    }

    .firstsection {
        padding-top: 100px !important;
    }
}

.fa-times:hover {
    color: #ffff !important;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.line1 {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 10%;
    left: 50%;
    background: #2d2d2d;
    overflow: hidden;
    z-index: -1;
}

.allsection .line1 {
    background: transparent !important;
}

.Editor_pick .line1 {
    background: transparent !important;
}

.line1::after {
    content: '';
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    z-index: -1;
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #00D1FF 75%, #00D1FF 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #00D1FF 75%, #00D1FF 100%);  
    -webkit-animation: drop 12s 0s infinite;  
            animation: drop 12s 0s infinite;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
            animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.line1:nth-child(1) {
    /* margin-left: -25%;  */
    margin-left: -38%;
}

.line1:nth-child(1)::after {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.line1:nth-child(3) {
    /* margin-left: 25%;  */
    margin-left: 40%;
}

.line1:nth-child(3)::after {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
}

.line1:nth-child(4) {
    margin-left: 25%;
}

.line1:nth-child(4)::after {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

@-webkit-keyframes drop {
    0% {
        top: -50%;
    }

    100% {
        top: 100%;
    }
}

@keyframes drop {
    0% {
        top: -50%;
    }

    100% {
        top: 100%;
    }
}




.top {
    top: 0;
    left: 0;
    width: 0;
    height: 3px;
    /* background: linear-gradient(90deg,
            transparent 50%,
            #74E842,
            #74E842); */
            background: -webkit-linear-gradient(left, transparent 50%, rgb(0 209 255), rgb(0 209 255));
            background: linear-gradient(90deg, transparent 50%, rgb(0 209 255), rgb(0 209 255));
}

.bottom {
    right: 0;
    bottom: 0;
    height: 3px;
    background: -webkit-linear-gradient(left,
    rgb(0 209 255),
    rgb(0 209 255),
            transparent 50%);
    background: linear-gradient(90deg,
    rgb(0 209 255),
    rgb(0 209 255),
            transparent 50%);
}

.right {
    top: 0;
    right: 0;
    width: 3px;
    height: 0;
    background: -webkit-linear-gradient(top,
            transparent 30%,
            #74E842,
            #74E842,
        );
    background: linear-gradient(180deg,
            transparent 30%,
            #74E842,
            #74E842,
        );
}

.left {
    left: 0;
    bottom: 0;
    width: 3px;
    height: 0;
    background: -webkit-linear-gradient(top,
            rgb(0 209 255),
            rgb(0 209 255),
            transparent 70%);
    background: linear-gradient(180deg,
            rgb(0 209 255),
            rgb(0 209 255),
            transparent 70%);
}

.top {
    -webkit-animation: animateTop 8s ease-in-out infinite;
            animation: animateTop 8s ease-in-out infinite;
}

.bottom {
    -webkit-animation: animateBottom 8s ease-in-out infinite;
            animation: animateBottom 8s ease-in-out infinite;
}

.right {
    -webkit-animation: animateRight 8s ease-in-out infinite;
            animation: animateRight 8s ease-in-out infinite;
}

.left {
    -webkit-animation: animateLeft 8s ease-in-out infinite;
            animation: animateLeft 8s ease-in-out infinite;
}

@-webkit-keyframes animateTop {
    25% {
        width: 100%;
        opacity: 1;
    }

    30%,
    100% {
        opacity: 0;
    }
}

@keyframes animateTop {
    25% {
        width: 100%;
        opacity: 1;
    }

    30%,
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes animateBottom {

    0%,
    50% {
        opacity: 0;
        width: 0;
    }

    75% {
        opacity: 1;
        width: 100%;
    }

    76%,
    100% {
        opacity: 0;
    }
}

@keyframes animateBottom {

    0%,
    50% {
        opacity: 0;
        width: 0;
    }

    75% {
        opacity: 1;
        width: 100%;
    }

    76%,
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes animateRight {

    0%,
    25% {
        opacity: 0;
        height: 0;
    }

    50% {
        opacity: 1;
        height: 100%;
    }

    55%,
    100% {
        height: 100%;
        opacity: 0;
    }
}

@keyframes animateRight {

    0%,
    25% {
        opacity: 0;
        height: 0;
    }

    50% {
        opacity: 1;
        height: 100%;
    }

    55%,
    100% {
        height: 100%;
        opacity: 0;
    }
}

@-webkit-keyframes animateLeft {

    0%,
    75% {
        opacity: 0;
        bottom: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 100%;
    }
}

@keyframes animateLeft {

    0%,
    75% {
        opacity: 0;
        bottom: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .line1 {
        background: transparent;
    }
}
/* header */

.navbar-dark .navbar-nav .nav-link {
    color: #fff !important;
}
/* #section_1,#scrollspyHeading1{
    background-color: red;
    height: 100vh;
}
#section_2,#scrollspyHeading2{
    background-color: yellow;
    height: 100vh;
}
#section_3,#scrollspyHeading3{
    background-color: brown;
    height: 100vh;
} */
/* .is-active{
    color: red;
} */
/* .activeborder{
    display: block;
} */

* {
  box-sizing: border-box
}

.alllisted .side-bar {
    width: 160px;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
  }
  
  .alllisted  nav ul {
    padding: 0;
  }
  
  .alllisted nav li {
    list-style: none;
  }
  
  .alllisted nav li a {
    text-decoration: none;
    color: #333;
    display: block;
    padding: 0.6rem 0.3rem;
    font-weight: 400;
    -webkit-transition: all ease-out 250ms;
    transition: all ease-out 250ms;
  }
  
  .alllisted nav li a:hover {
    color: lime;
  }
  
  .alllisted .main-content {
    margin-left: 160px;
  }
  
  .alllisted section {
    background-color: #f2f2f2;
    height: 90vh;
    margin:0;
    padding:2.5rem 4rem;
  }
  
  .alllisted section:nth-of-type(2n) {
    background-color: #ccc;
  }
  
  .alllisted section:last-of-type {
    height: 100vh;
  }
  .alllisted nav li a.active {
    color: lime;
  }

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
}

video::-webkit-media-controls {
    display:none !important;
}
video::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}
video::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}
video::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
.normalborder{
    display: block;
}
.activeborder{
    display: none;
}
.is-active .normalborder{
    display: none;
}
.is-active .activeborder{
    display: block;
}
/* .textconete p{
    display: none !important;
    margin-bottom: 0px !important;
} */

.navmenulist {
    max-width: 15%;
}

.headergpt ul {
    padding-left: 0px !important;
    list-style-type: none;
    font-family: 'Space Mono', monospace;
}

.headergpt li a {
    color: #fff;
}

.headergpt li a:hover {
    color: #FF67FF !important;
    /* color: var(--greens) !important; */
    cursor: pointer;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.homeheaders {
    position: absolute;
    top: 28%;
    left: 13%;
}

.typed-cursor {
    color: #FF67FF !important;
}

/* .section2 .typed-cursor{
    color: #fff !important;
} */
.Homepage {
    padding: 0px 0px;
    font-family: 'Space Mono', monospace;
}

.openbook {
    background: url(/static/media/ship2.9e7a8fc8.gif);
    background-repeat: no-repeat;
    width: 100%;
    background-size: 100% 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.one1 {
    position: absolute;
    right: 20%;
    top: 18%;
}

.ship {
    background: url(/static/media/dock2.37f5d235.jpg);
    background-repeat: no-repeat;
    /* width: 100%; */
    /* background-size: 100% 100%; */
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-size: 100% 100%;
    background-position: center;
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
    /* backdrop-filter: red; */
    /* z-index: 3; */
}

/* .ship::before{
    content: "";
    background: url("./Assets/images/slide/tech.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: 100% 100% !important;
    height: 100vh;
    position: absolute;
    z-index: 0;
} */
.section4 .ship {
    /* filter: brightness(0.5) !important; */
}

.section4 .ship1::before {
    content: "";
    background: url(/static/media/stars.e4147d6d.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    max-width: 66%;
    min-width: 66%;
    /* height: 100%; */
    height: 90%;
    max-height: 90%;
    min-height: 90%;
    background-size: 100% 100% !important;
    position: absolute;
    top: 6%;
    left: 17%;
    z-index: -1;
    border-radius: 10%;
}

.section5 .ship1::before {
    content: "";
    background: url(/static/media/planetbg.e026123b.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    max-width: 72.5%;
    min-width: 72.5%;
    /* height: 100%; */
    height: 93%;
    max-height: 93%;
    min-height: 93%;
    background-size: 100% 100% !important;
    position: absolute;
    top: 4%;
    left: 14.7%;
    z-index: -1;
    border-radius: 8% 12% 8% 12%;
}

/* .ship::after{
    content: "";
    background: url("./Assets/images/slide/screen.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: 100% 100%;
    height: 100vh;
    position: absolute;
    z-index: 1;
} */
.openbook::before {
    content: "";
    background: url(/static/media/star-travel.9a5092a7.gif);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: 100% 100%;
    height: 100vh;
    position: absolute;
    z-index: -5;
}

.section2 .openbook::before {
    content: "";
    background: url(/static/media/bg.653f6151.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: 100% 100%;
    height: 100vh;
    position: absolute;
    z-index: -5;
}

.homesec {
    position: absolute;
    /* top: 36%;
    left: 35%; */
    text-align: center;
    font-family: 'Space Mono', monospace;
    Left: 50% !important;
    top: 40% !important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.hometitle {
    color: #FF67FF;
    font-size: 40px;
    font-family: 'Space Mono', monospace;
    font-weight: 700;
}

.subtitle {
    color: #00D1FF
}

.topgif {
    max-width: 34%;
    position: absolute;
    top: 3%;
    left: 33%;
}

.bottomgif {
    max-width: 34%;
    position: absolute;
    bottom: 20%;
    left: 33%;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
}

.targetcircle {
    position: absolute;
    max-width: 24%;
    left: 39%;
    top: 20%;
    -webkit-filter: brightness(0.3);
            filter: brightness(0.3);
    z-index: -1;
}

.globecircle {
    position: absolute;
    max-width: 7%;
    bottom: 8%;
    right: 30%;
}

.hud {
    position: absolute;
    bottom: 20%;
    max-width: 10%;
    left: 13%;
}

.redgredient {
    position: absolute;
    background-image: -webkit-linear-gradient(rgb(255 0 0 / 18%), rgb(255 0 0 / 18%));
    background-image: linear-gradient(rgb(255 0 0 / 18%), rgb(255 0 0 / 18%));
    max-width: 600px;
    width: 600px;
    -webkit-filter: blur(50px);
            filter: blur(50px);
    height: 200px;
    z-index: -1;
    -webkit-transform: rotate(357deg);
            transform: rotate(357deg);
    top: -18%;
    left: 28%;
}

.violentgredient {
    position: absolute;
    background-image: -webkit-linear-gradient(#170e2b, #170e2b);
    background-image: linear-gradient(#170e2b, #170e2b);
    max-width: 500px;
    width: 500px;
    -webkit-filter: blur(50px);
            filter: blur(50px);
    height: 200px;
    z-index: -1;
    -webkit-transform: rotate(357deg);
            transform: rotate(357deg);
    bottom: -4%;
    left: 30%;
}

.loadimg {
    position: absolute;
    max-width: 12%;
    -webkit-transform: rotate(342deg);
            transform: rotate(342deg);
    top: 14%;
    left: 14%;
}

.launch {
    position: absolute;
    bottom: 22%;
    right: 20%;
    max-width: 13%;
}

.launch-name {
    position: absolute;
    bottom: 32%;
    right: 24%;
    max-width: 12%;
    color: #CB08C5;
    text-transform: uppercase;
    font-size: 18px !important;
    font-weight: 700;
    font-family: 'Space Mono', monospace;
}

.loopsimg {
    position: absolute;
    top: 12%;
    right: 9%;
    max-width: 20%;
    -webkit-transform: rotate(11deg);
            transform: rotate(11deg);
    /* filter: brightness(0.5); */
    /* filter: blur(1px); */
}

.launchpad .loopsimg {
    right: 2%;
}

.button.type1 {
    color: var(--greens);
    position: relative;
    padding: 8px 20px !important;
    border: 1px solid #ffff;
    border-radius: 4px;
}

.button.type1.type1::after,
.button.type1.type1::before {
    content: '';
    display: block;
    position: absolute;
    width: 20%;
    height: 20%;
    border: 1px solid #ffff;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    border-radius: 4px;
}

.button.type1.type1::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #ffff;
    border-right-color: #ffff;
}

.button.type1.type1::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: #ffff;
    border-left-color: #ffff;
}

.button.type1.type1:hover:after,
.button.type1.type1:hover:before {
    width: 100%;
    height: 100%;
}

.launchpad .targetcircle {
    top: -20%;
}

.lockedstake .targetcircle {
    top: -20%;
}

.loopsleftimg {
    position: absolute;
    top: 12%;
    left: 9%;
    max-width: 20%;
    -webkit-transform: rotate(345deg);
            transform: rotate(345deg);
    /* filter: brightness(0.5); */
    /* filter: blur(1px); */
}

.launchpad .loopsleftimg {
    left: 2%;
}

.navmenus {
    position: absolute;
    right: 12%;
    top: 16%;
    max-width: 7%;
}


/* section2 */

.seccon {
    padding: 10%;
}

.seccon1 {
    padding: 4% 10% 8% 10%;
}

.seccon2 {
    padding: 5% 10%;
}

.f-13 {
    font-size: 13px;
}

.f-11 {
    font-size: 10px;
}

.fw-700 {
    font-weight: 700;
}

.planetcircle {
    position: absolute;
    max-width: 5%;
    bottom: 42%;
    left: 46%;
}

.para {
    position: relative;
}

.topborder {
    position: absolute;
    max-width: 7%;
    left: 0;
}

.bottomborder {
    position: absolute;
    max-width: 7%;
    right: 6%;
    bottom: 1%;
}

.subparas {
    padding: 10px 10px;
}

.hudgifs {
    position: absolute;
    max-width: 100%;
    left: -24%;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    bottom: -15%;
}

.navigationmenu {
    position: fixed;
    top: 25%;
    right: 10%;
    z-index: 2;
}

.navborder {
    max-width: 30%;
}

.navborder1 {
    position: absolute;
    top: -13%;
    right: 58%;
    max-width: 100%;
}

.linear-rel {
    position: relative;
    z-index: 2;
}

.linearbg {
    position: absolute;
    max-width: 100%;
    top: -26%;
    width: 100%;
    z-index: -1;
}

.navigationmenu ul {
    padding-left: 0px !important;
    list-style-type: none;
    position: absolute;
    top: 24%;
    left: -12%;
}

.navigationmenu li {
    cursor: pointer;
}

.grayborder {
    max-width: 60%;
    /* width: 100%; */
}

.section3 {
    height: 100vh;
}

/* section3 */
.glitchimg {
    height: 100vh;

}

.screenimg {
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: -1;
}
.imgdiv{
  
    margin-top: 50px !important;
    max-width: 100% !important;
}
.partner1 {
    width: 30vw;
    max-height: 3vw;
    object-fit: contain;
    max-width: 100% !important;
    /* filter: drop-shadow(2px 2px 26px #fff);
    background: transparent;
    box-shadow: 0 0 50px white; */
    /* animation: glow 3s infinite alternate; */
}

.partner1:hover {
    -webkit-filter: drop-shadow(2px 2px 26px #fff);
            filter: drop-shadow(2px 2px 26px #fff)
}

@-webkit-keyframes glow {
    from {
        box-shadow: 0 0 10px -10px #ffffff26;
    }

    to {
        box-shadow: 0 0 10px 10px #ffffff26;
    }
}

@keyframes glow {
    from {
        box-shadow: 0 0 10px -10px #ffffff26;
    }

    to {
        box-shadow: 0 0 10px 10px #ffffff26;
    }
}

/* section4 */
.section4 {
    position: relative;
}

.section4 h3,
h6 {
    color: #fff !important;
    font-weight: 700 !important;
}

.sustainable {
    max-width: 40%;
    margin: auto;
}

.f-12 {
    font-size: 12px;
}

.videoTag {
    width: 100%;
    height: 100vh;
    object-fit: fill;
    position: absolute;
    z-index: -1;
    /* padding: 4% 0%; */
}

.leftcamera {
    position: absolute;
    max-width: 10% !important;
    left: 9%;
    top: 6%;
    width: 100%;
}

.rightcamera {
    position: absolute;
    max-width: 10% !important;
    right: 9%;
    top: 6%;
    width: 100%;
}

.power-gauge {
    max-width: 80%;
    margin-top: -10%;
}

/* section5 */
.spaceship {
    background: url(/static/media/dock.6901cee3.png);
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    z-index: -1;
    /* position: relative;
    overflow: hidden;
    background-size: 100% 100%;
	background-position:center;	
    filter: brightness(0.9); */

}
.section5,.section4{
    background: url(/static/media/dock2.37f5d235.jpg);
    background-repeat: no-repeat;
    padding: 8px 0px 8px 0px;
    background-position: center;
    background-size: 100% 100%;
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
    position: relative;
    overflow: hidden;
    /* z-index: 7; */
}
.logoimg{
    /* left: 45%;
    position: absolute;
    top: 15%; */
    position: absolute;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 16% !important;
}
.section5 h3,
h6 {
    color: #fff !important;
    font-weight: 700 !important;
}

.f-14 {
    font-size: 14px;
}

.spaceman {
    position: absolute;
    max-width: 40% !important;
    left: 8%;
    top: 34%;
    width: 100%;
    z-index: -1;
}

.section5 .seccon2 {
    padding: 4% 10% !important;
    max-width: 60rem;
}

.planet1 {
    max-width: 90%;
}

.section5 .row:nth-child(2) {
    padding-left: 5%;
}

.section5 .row:nth-child(3) {
    padding-left: 12%;
}

.section5 .row:nth-child(4) {
    padding-left: 16%;
}


/* .section5 .row:nth-child(5) {
    padding-left: 19%;
} */

.footer1 {
    border-top: 1px solid #80808096;
}


/* .parallax {
    background: url("./Assets/images/slide/dock.png");
    min-height: 1000px; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .parrr{
    height:1000px;
  } */


/* .last{
    position: relative;
}
.frr {
    width: 150px;
    height: 150px;
    background-color: black;
}
.fade {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
}
.zoom {
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
}

.afterzoom {
    position: absolute;
    height: 200vh;
    width: 100%;
    background: red;
    overflow-x: auto;
} */

.headers {
    display: block;
}

.headers1 {
    display: none;
}

.offcanvas {
    background: url(/static/media/star-travel.9a5092a7.gif);
    background-repeat: no-repeat;
    width: 100%;
    background-position: center;
}

.react-transform-wrapper{
    padding-top: 6% !important;
}

@media only screen and (min-width: 1601px) and (max-width: 1920px) {
    .homesec {
        /* left: 38%; */
    }
    .homeheaders {
        top: 34%;
    }
    .partner1 {
       margin-top: 35% !important;
    }
    .mind{
        padding-top: 10% !important;
    }
    .section4 .ty .big{
        padding-top: 6% !important;
    }
    .big1{
        padding-top: 3% !important;
    }
    .big2{
        padding-top: 6% !important;
    }
    .footer1{
        margin-top: 6% !important;
    }
    .section5 .seccon2 {
        padding: 4% 4% !important;
    }
    /* .openbook{
        height: unset;
    } */
}

@media only screen and (min-width: 1400px) and (max-width: 1500px) {

    .para {
        margin-top: 12%;
    }

    .homesec {
        /* left: 36%; */
    }

    /* .launch {
        bottom: 21%;
        right: 18%;
        max-width: 17%;
    } */
}

@media only screen and (max-width: 1200px) {
    .launch {
        bottom: 18%;
        right: 17%;
        max-width: 20%;
    }

    .para {
        margin-top: 3%;
    }
    .plusminus{
        display: unset !important;
        justify-content: center !important;
    }
    .plusminus .justify-content-end{
        justify-content: center !important;
    }
}

@media only screen and (max-width: 1199px) {
    .imgdiv {
       
        margin-top: 50px !important;
        max-width: 100% !important;
      }
      .imgdiv .partner1
      {
        width: 12vw;
        max-height: 3vw;
        object-fit: contain;
      }
    /* .section3 {
        height: unset;
    } */
    .section4 .ship::before {
        max-width: 78%;
        min-width: 78%;
        left: 11%;
    }

    .launch {
        bottom: 22%;
        right: 17%;
        max-width: 21%;
    }

    .navigationmenu {
        right: 0%;
    }

    .homeheaders {
        left: 10%;
    }

    .targetcircle {
        max-width: 35%;
        left: 33%;
    }

    .homesec {
        /* left: 32%; */
    }
}


@media only screen and (max-width: 991px) {

    .section3 {
        height: unset;
    }

    .headers {
        display: none;
    }

    .section5 .seccon2 {
        padding: 8% 10% !important;
    }

    .one1 {
        display: none;
    }

    .seccon2 {
        padding: 8% 10%;
    }

    .headers1 {
        display: block;
    }

    .targetcircle {
        max-width: 44%;
        left: 28%;
        top: 20%;
    }
    .launch {
        bottom: 21%;
        right: 14%;
        max-width: 24%;
    }
    .hudgifs {
        bottom: -1%;
    }
    .listimg {
        max-width: 10%;
    }
    .headers1 .btn-primary {
        background-color: transparent;
        border-color: transparent;
        text-align: left;
        position: fixed;
        /* top: 2%;
        left: 2%; */
        z-index: 777;
    }

    .offcanvas .btn-close {
        -webkit-filter: invert(1);
                filter: invert(1);
    }

    .offcanvas-title {
        color: #FF67FF;
    }

    .offcanvas ul {
        padding-left: 0px !important;
        list-style-type: none !important;
    }

    .offcanvas li {
        padding-bottom: 15px;
    }

    .offcanvas li a {
        color: #fff;
    }

    .navmenulist {
        max-width: 8%;
    }

    .homeheaders {
        top: 0%;
        left: 0%;
    }

    .homesec {
        /* left: 22%; */
    }

    .navigationmenu {
        right: 0%;
    }

    .section4 .ship::before {
        max-width: 92%;
        min-width: 92%;
        left: 4%;
    }
    .logoimg {
        /* left: 44%; */
    }
}


@media only screen and (max-width: 767px) {
    .imgdiv {
     
        margin-top: 10px !important;
        max-width: 100% !important;
      }

      .imgdiv .partner1
      {
        width: 50vw;
        max-height: 3vw;
        object-fit: contain;
      }
    .logoimg {
        /* left: 37%; */
    }
    .navigationmenu {
        display: none;
    }

    .partner1 {
        max-width: 100% !important;
    }

    .targetcircle {
        max-width: 44%;
        left: 27%;
        top: 22%;
    }

    .homesec {
        /* left: 10% !important; */
        padding: 0px 10px;
    }

    .navigationmenu {
        right: -14% !important;
    }

    .navborder {
        max-width: 20%;
    }

    .mt-top {
        margin-top: 12%;
    }

    .ty {
        height: 370px;
        overflow-y: auto;
        padding-left: 12px;
        padding-right: 12px;
    }

    .ty1 {
        height: 450px !important;
    }

    .ty::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
    }

    .ty::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    .ty::-webkit-scrollbar-thumb {
        background-color: #000000;
    }

    .launch {
        bottom: 22%;
        right: 16%;
        max-width: 28%;
    }

    .hudgifs {
        bottom: -30%;
    }

    .power-gauge,
    .planet1,
    .sustainable {
        max-width: 100%;
    }

    /* .hometitle {
        color: #FF67FF;
        font-size: 30px;
        font-family: 'Space Mono', monospace;
        font-weight: 700;
    } */
}

@media only screen and (max-width: 575px) {
.imgdiv .partner1 {
    max-height: 7vw;
}
    .ship{
        height: unset;
    }
    .logoimg {
        /* left: 27%; */
    }
    .react-transform-wrapper{
        padding-top: 46% !important;
    }

    .news .itemsfles {
        left: 10px;
    }

    .hudgifs {
        bottom: -10%;
    }

    .navigationmenu {
        right: -18% !important;
    }

    .homesec {
        /* left: 7% !important; */
    }

    .launch-name {
        bottom: 29%;
        right: 25%;
        font-size: 12px !important;
    }

    .offcanvas ul .ms-2,
    .ms-3,
    .ms-4,
    .ms-5 {
        margin-left: 0px !important;
    }

    .navmenulist {
        max-width: 12%;
    }

    .listimg {
        max-width: 14%;
    }

    .hometitle {
        font-size: 28px;
    }

    .subtitle {
        font-size: 10px;
    }

    .targetcircle {
        max-width: 70%;
        left: 14%;
        top: 25%;
    }

    .navborder {
        max-width: 12%;
    }

    .navborder1 {
        top: -30%;
        right: 78%;
        max-width: 75%;
    }

    .grayborder {
        max-width: 50%;
    }

    .navigationmenu ul {
        left: -20%;
    }

    .launch {
        bottom: 22%;
        right: 12%;
        max-width: 36%;
    }


    .linear-rel h5 {
        padding-left: 12px;
    }

    .f-14 {
        font-size: 11px;
    }

    .seccon2 {
        padding: 14% 10% 5% 10% !important;
    }

    .section4 .ship::before {
        max-width: 92%;
        min-width: 92%;
        left: 4%;
    }

    .section5 .spaceship::before {
        height: 94%;
        max-height: 94%;
        min-height: 94%;
    }

    .sub-heading {
        font-size: 11px;
    }

    .section4 h3 {
        font-size: 17px;
    }

    .section5 h3 {
        font-size: 17px;
    }

    .section5 .seccon2 {
        padding: 12% 10% 4% 10% !important;
    }

    .spaceman {
        max-width: 92% !important;
        left: -4%;
        top: 32%;
        z-index: -1;
    }

}


@media only screen and (max-width: 450px){
.logoimg {
    /* left: 31%; */
}
}

@media only screen and (min-width:1200px) and (max-width:1350px)
{
    .navigationmenu
    {
        right:5%;
    }
}

@media only screen and (min-width:992px)
{
    .section3
    {
    display: flex;
    align-items: center;
    }
}
